<template>
  <router-view/>
</template>

<script>
import * as contactsGetters from '../store/contacts/getters';
import * as contactsActions from '../store/contacts/actions';
import * as navActions from '../store/nav/actions';

export default {
  inject: ['forbid'],
  data() {
    return {};
  },
  watch: {
    currentContact(newValue) {
      const { firstName = null, lastName = null, email } = newValue || {};
      let displayName = email;
      if (firstName != null || lastName != null) {
        displayName = `${firstName || ''} ${lastName || ''}`.trim();
      }
      const breadcrumbs = newValue
        ? [
          {
            name: 'Contacts',
            status: '',
            href: '/',
          },
          {
            name: displayName,
            status: '',
            href: `/contact/${newValue.id}`,
          },
        ]
        : [];
      this.$store.dispatch(`nav/${navActions.SET_BREADCRUMBS}`, breadcrumbs);
    },
  },
  methods: {
    async loadContact() {
      try {
        await this.$store.dispatch(`contacts/${contactsActions.GET_ITEM_BY_ID}`, {
          contactId: this.currentContactId,
          setAsCurrent: true,
        });
      } catch (error) {
        if (error?.response?.status === 403) {
          this.forbid();
        } else {
          throw error;
        }
      }
    },
  },
  computed: {
    currentContactId() {
      return this.$route.params.contactId;
    },
    currentContact() {
      const data = this.$store.getters[
        `contacts/${contactsGetters.CURRENT_ITEM}`
      ];
      return data;
    },
  },
  created() {
    this.loadContact();
  },
};
</script>
